<template>
  <div class="main">
    <nav class="header" :class="{'shadow': scrolled}">
      <router-link to="/">
        <img class="header-logo" src="@/assets/apharma-logo.png" alt="apharma logo">
      </router-link>
      <router-link to="/">Strona główna</router-link>
      <router-link to="/training">Szkolenia zewnetrzne</router-link>
      <router-link to="/contact">Kontakt</router-link>
    </nav>
    <router-view id="router"/>
    <div class="footer">
      <router-link to="/cookies">Polityka Cookies</router-link><br>
      <span>© Apharma {{ new Date().getFullYear() }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    handleScroll () {
      this.scrolled = window.scrollY > 10;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>

<style>
  @import './assets/styles/styles.css';
</style>

<style scoped>
  .main {
    display: contents;
  }

  .footer {
    box-shadow: 0px 0px 10px rgba(0,0,0,.6);
    background-color: #14267b;
    color: white;
    padding: 12px;
    text-align: center;
  }
  .footer a {
    color: white;
  }
  .footer a:hover {
    color: grey;
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .header.shadow {
    background: transparent;
    backdrop-filter: blur(10px) grayscale(30%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .header-logo {
    left: 24px;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  nav {
    padding: 30px;
    text-align: center;
  }
  nav a, .menu {
    font-weight: bold;
    color: black;
    text-decoration: none;
    margin: 0 12px;
    font-size: 1.4rem;
  }
  nav a:hover, .menu:hover {
    color: rgb(15, 0, 89);
    cursor: pointer;
  }
  nav a.router-link-exact-active {
    color: black;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    nav a:nth-child(2) {
      margin-left: 100px;
    }
    nav a {
      font-size: 12px;
      margin: 4px 4px;
      display: inline-block;
    }
    .header-logo {
      max-width: 25vw;
      top: 18px;
    }
  }
  @media (max-width: 1280px) {
    nav a:nth-child(2) {
      margin-left: 200px;
    }
    nav a {
      font-size: 16px;
    }
  }
</style>
