import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore, collection, getDocs, getDoc, doc, addDoc, updateDoc, deleteDoc} from 'firebase/firestore/lite';
import {getAnalytics} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDqpot4QKoowNy9yw2LgodzqtjIrMWNLNE',
  authDomain: 'apharma-b7faa.firebaseapp.com',
  projectId: 'apharma-b7faa',
  storageBucket: 'apharma-b7faa.appspot.com',
  messagingSenderId: '701594764438',
  appId: '1:701594764438:web:442f65a3539f32c7512eb7',
  measurementId: 'G-R83QGJ4SY2',
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);

/** ARTICLES */

const articleCol = collection(db, 'articles');

export function getArticles() {
  return getDocs(articleCol);
}

export function getArticle(id) {
  const docRef = doc(db, 'articles', id);
  return getDoc(docRef);
}

export function addArticle(content) {
  return addDoc(articleCol, {content});
}

export function modifyArticle(content, id, isRestricted = false) {
  const docRef = doc(db, 'articles', id);
  return updateDoc(docRef, {content, isRestricted});
}

export function deleteArticle(id) {
  const docRef = doc(db, 'articles', id);
  return deleteDoc(docRef);
}

/** NEWS */

const newsCol = collection(db, 'news');

export function getAllNews() {
  return getDocs(newsCol);
}

export function getNews(id) {
  const docRef = doc(db, 'news', id);
  return getDoc(docRef);
}

export function addNews(content, title) {
  return addDoc(newsCol, {content, title, date: Date.now()});
}

export function modifyNews(content, title, id) {
  const docRef = doc(db, 'news', id);
  return updateDoc(docRef, {content, title, dateUpdated: Date.now()});
}

export function deleteNews(id) {
  const docRef = doc(db, 'news', id);
  return deleteDoc(docRef);
}

export {auth, analytics};
