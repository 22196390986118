import {createStore} from 'vuex';
import {auth, getArticles, getAllNews, modifyArticle, addNews, modifyNews, getArticle, deleteNews} from '@/firebase';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

const store = createStore({
  state: {
    user: null,
    articles: [],
    news: [],
  },
  getters: {
    user: (state) => state.user,
    articles: (state) => state.articles,
    news: (state) => state.news,
    getArticle: (state) => (id) => state.articles.find((article) => article.id === id),
    getNews: (state) => (id) => state.news.find((news) => news.id === id),
    isAdmin: (state) => state.user?.email === 'admin@apharma.com.pl',
    isUser: (state) => state.user?.email === 'user@apharma.com.pl',
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload;
    },
    setArticles(state, payload) {
      state.articles = payload;
    },
    setNews(state, payload) {
      state.news = payload.sort((a, b) => b.data.date - a.data.date);
    },
  },
  actions: {
    async fetchData(context) {
      await context.dispatch('getArticles');
      await context.dispatch('getNews');
    },
    async login(context, {email, password}) {
      const res = await signInWithEmailAndPassword(auth, email, password);
      if (res) {
        context.commit('setUser', res.user);
      } else {
        throw new Error('could not complete login');
      }
    },
    async logout(context) {
      await signOut(auth);
      context.commit('setUser', null);
    },
    getArticles(context) {
      return getArticles().then((res) => context.commit('setArticles', res.docs.map((doc) => ({id: doc.id, data: doc.data()}))));
    },
    getArticle(context, id) {
      return getArticle(id).then((res) => context.commit('setArticles', [...context.state.articles, {id: res.id, data: res.data()}]));
    },
    modifyArticle(context, {content, id, isRestricted}) {
      return modifyArticle(content, id, isRestricted).then(() => context.dispatch('getArticles'));
    },
    getNews(context) {
      return getAllNews().then((res) => context.commit('setNews', res.docs.map((doc) => ({id: doc.id, data: doc.data()}))));
    },
    addNews(context, {content, title}) {
      return addNews(content, title).then(() => context.dispatch('getNews'));
    },
    modifyNews(context, {content, title, id}) {
      return modifyNews(content, title, id).then(() => context.dispatch('getNews'));
    },
    deleteNews(context, id) {
      return deleteNews(id).then(() => context.dispatch('getNews'));
    },
  },
});

onAuthStateChanged(auth, (user) => {
  store.commit('setUser', user);
  store.dispatch('fetchData');
});

export default store;
