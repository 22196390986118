import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

createApp(App)
    .use(router)
    .use(store)
    .component('QuillEditor', QuillEditor)
    .mount('#app');
