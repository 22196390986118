import {createRouter, createWebHistory} from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "program" */ '../views/HomeView.vue'),
  },
  {
    path: '/training',
    name: 'training',
    component: () => import(/* webpackChunkName: "training" */ '../views/ArticleView.vue'),
    props: {artId: '02Vz7gU3aQmIUTDpU3FB'},
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ArticleView.vue'),
    props: {artId: '0N4B5aWnGcnzAov7Bqze'},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    props: {isLogin: true},
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/CookiesView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

export default router;
